import React , { useState, useEffect }from 'react';
import { Container,Typography, IconButton } from "@mui/material";
import AppleIcon from '@mui/icons-material/Apple';
import "./AddressDetail.css";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import BoxDetail from './components/BoxDetail';
import alerta from "../../images/alerta.png"
import IconArrow from "../../images/ICON_ARROW.png"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MessageAddress from './components/MessageAddress/MessageAddress';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSection } from '../../context/SectionContext';
import {useDatosUser} from "../../context/userContext";
import {useNavigate} from "react-router-dom";
import useRedirectIframe from "../../hooks/useRedirectIframe";


const AddressDetail = () => {
    const navigate = useNavigate();
    const { nombre, completed, wuujuID, phone } = useDatosUser();

    useRedirectIframe('https://wuuju.co/pages/casillero-virtual/');

    useEffect(() => {
        if (completed === false || completed === null) {
            navigate('/crear-casillero-2');
        }
    }, [completed, navigate]);

    const { optionalVariable, changeSection } = useSection();
    const options = [
        //Compras Apple BY:BETANCUR SAPATO
        {
        name: "apple",
        apellido:"WJ Carlos Paez",
        text: "Compras en Apple",
        color: "#0a0a0a" ,
        pText: (
            <>
                <p className="message-text-default">En esta dirección
                    <span className="message-text-color"> NO recibimos paquetes</span> de otras tiendas que no sea la de Apple
                    USA oficial.</p>
                <p className='message-text-default'>Recuerda que debes programar tus entregas de Lunes a Viernes de 9:00am a 3:30pm</p>
            </>
        ),
            messageHeight: "16vw",
        icon: <AppleIcon sx={{  fontSize: {xs: '4vw', sm: '2vw'}, marginLeft:"0.6vw" }} />,
        variable: {
            Direccion: "10185 Collins AVE",
            Apto: "Apto 715",
            Estado: "Florida - FL",
            Ciudad: "Bal Harbour",
            CodigoPostal: "33154",
        }},
        //Compras Menores a 200 USD Express BY:BETANCUR SAPATO
        {
        name: "express",
        apellido:`${wuujuID} EX`,
        text: "Compras Menores a 200 USD Express",
        color: "#b3df53" ,
            pText: (
                <>
                    <p className="message-text-default">Así es, en <span className="message-text-color">96 horas hábiles</span> ponemos
                        tus compras en la puerta de tu casa. </p>
                    <p className='message-text-default'>No olvides que sólo podrás enviar <span className="message-text-color">hasta 6 unidades</span>
                    del mismo producto.</p>
                    <p className='message-text-default'>Recuerda debes programar tus entregas de Lunes a Viernes de 9:00am a 3:30pm.</p>
                </>
            ),
            messageHeight: "23vw",
        icon: <ShoppingCartOutlinedIcon sx={{  fontSize: {xs: '5vw', sm: '2vw'}, marginLeft:{xs: '1vw', sm: '0.6vw'} }} />,
        variable: {
            Direccion: "2120 NW 96TH AVE",
            Estado: "Florida - FL",
            Ciudad: "Miami",
            CodigoPostal: "33122",
        }},
        //Compras Mayores a 200 USD BY:BETANCUR SAPATO
        {
        name: "major",
        apellido: `${wuujuID} 9240009`,
        text: "Compras Mayores a 200 USD",
        color: "#FB8065" ,
            pText: (
                <>
                    <p className='message-text-default'>A esta dirección puedes enviar <span className="message-text-color">las cantidades
                        que quieras y los productos que quieras</span> menos juguetes bélicos, de resto, lo que seaaaa.</p>
                    <p className='message-text-default'>Recuerda que debes programar tus entregas de Lunes a Viernes de 9:00am a 3:30pm.</p>
                </>
            ),
            messageHeight: "18vw",
        icon: <ShoppingCartOutlinedIcon sx={{  fontSize: {xs: '4vw', sm: '2vw'}, marginLeft:"0.6vw" }} />,
        variable: {
            Direccion: "10900 NW 21ST. ST220",
            Apto: "Suite #220",
            Estado: "Florida - FL",
            Ciudad: "Miami",
            CodigoPostal: "33172-2006",
        }},
        //Compras Menores a 200 USD BY:BETANCUR SAPATO
        { name: "minor",
        apellido:wuujuID,
        text: "Compras Menores a 200 USD",
        color: "#009ad5" ,
            pText: (
                <>
                    <p className='message-text-default'>No olvides que solo podrás
                        enviar <span className="message-text-color">hasta 6 unidades</span> del mismo producto.</p>

                    <p className='message-text-default'>Recuerda que debes programar tus entregas de Lunes a Viernes de 9:00am a 3:30pm.</p>
                </>
            ),
        messageHeight: "15vw",
        icon: <ShoppingCartOutlinedIcon sx={{  fontSize: {xs: '4vw', sm: '2vw'}, marginLeft:"0.6vw" }} />,
        variable: {
            Direccion: "2120 NW 96TH AVE",
            Estado: "Florida - FL",
            Ciudad: "Miami",
            CodigoPostal: "33122",
        }},
    ];

            // Encontrar el index de options segun el name
            const findIndexByName = (name) => {
                return options.findIndex(item => item.name === name);
            };

            // Navegación index de options, el contexto optionalVariable define el index default
            const [selectedOptionIndex, setSelectedOptionIndex] = useState(findIndexByName(optionalVariable));

            const handleNextClick = () => {
                setSelectedOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
            };
            
            const handlePrevClick = () => {
                setSelectedOptionIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
            };
            


    return (
            <>
            <IconButton
                aria-label="close"
                onClick={() => changeSection('direcciones')}
                style={{ position: 'absolute', left: '1vw', top: '0.2vw'}}
            >
                <ArrowBackIcon sx={{
                            color: "#5452ff",
                            fontSize: { xs: '5vw', sm: '1vw', md: '1vw', lg: '1.5vw', xl: '2vw' },
                            p: "1px",
                        }}/> <span className='boton-regresar' style={{paddingLeft: '0.5vw', fontFamily:'Viga'}}>Regresar</span>
            </IconButton>
            <Container maxWidth="xl" className='address-main' sx={{paddingTop: "2vw"}}>
            <h1 className='title-direccion'>Tu dirección en Miami para</h1>
            <div className='compra-variable-container'>
                {/* Imagen para el botón "Anterior" */}
                <img
                    src={IconArrow}
                    alt="Anterior"
                    onClick={handlePrevClick}
                    className='custom-button1'
                />
                {/* Selector de tipo de envio*/}
                <div className='compra-variable' style={{ backgroundColor: options[selectedOptionIndex].color }}>
                    {options[selectedOptionIndex].icon}
                    <span>{options[selectedOptionIndex].text}</span>
                </div>
                {/* Imagen para el botón "Siguiente" */}
                <img
                    src={IconArrow}
                    alt="Siguiente"
                    onClick={handleNextClick}
                    className='custom-button2'
                />
            </div>
            
                <p className='general-text'> <img src={alerta} alt="" className='img-alert2' /> Revisa los
                <a href="https://wuuju.co/pages/casillero-virtual-instructivo" rel="noreferrer" target="_blank" className='terminos-text'> términos, condiciones, tarifas e instrucciones de uso aquí</a></p>
            
                    <BoxDetail 
                        label="Nombre"
                        variable={nombre}
                        icon={<PermIdentityOutlinedIcon sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />}
                        color={options[selectedOptionIndex].color}
                    /> 
                    <BoxDetail 
                        label="Apellido"
                        variable={options[selectedOptionIndex].apellido}  
                        icon={<PermIdentityOutlinedIcon sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />}   
                        color={options[selectedOptionIndex].color}               
                    /> 
                    <BoxDetail 
                        label="Dirección"
                        variable={options[selectedOptionIndex]?.variable.Direccion}
                        icon={<PlaceOutlinedIcon sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />}   
                        color={options[selectedOptionIndex].color}               
                    />
                    {options[selectedOptionIndex]?.variable.Apto && (
                        <BoxDetail
                            label="Apto"
                            variable={options[selectedOptionIndex].variable.Apto}
                            icon={<PlaceOutlinedIcon sx={{ fontSize: {xs: '4vw', sm: '1.3vw'} }} />}
                            color={options[selectedOptionIndex].color}
                        />
                    )}
                    <BoxDetail 
                        label="Estado"
                        variable={options[selectedOptionIndex]?.variable.Estado}
                        icon={<PlaceOutlinedIcon  sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />}  
                        color={options[selectedOptionIndex].color}          
                    /> 
                    <BoxDetail 
                        label="Ciudad"
                        variable={options[selectedOptionIndex]?.variable.Ciudad}
                        icon={<PlaceOutlinedIcon  sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />} 
                        color={options[selectedOptionIndex].color}                 
                    /> 
                    <BoxDetail 
                        label={<>Código <br /> Postal</>}
                        variable={options[selectedOptionIndex]?.variable.CodigoPostal}
                        icon={<PlaceOutlinedIcon  sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />}  
                        color={options[selectedOptionIndex].color}                
                    /> 
                    <BoxDetail 
                        label="Teléfono"
                        variable={phone}
                        icon={<PhoneOutlinedIcon sx={{  fontSize: {xs: '4vw', sm: '1.3vw'} }} />} 
                        color={options[selectedOptionIndex].color}                  
                    /> 
                    <MessageAddress
                        text={options[selectedOptionIndex]?.pText}
                        height={options[selectedOptionIndex]?.messageHeight}
                    />
                    <div className='typography-content-1'>
                    <Typography
                        sx={{
                            color: 'gray',
                            fontFamily: 'Viga, sans-serif',
                            fontSize: {xs: '3vw', sm: '1vw'},
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        ─── ¿Tienes dudas?,
                        <span className='whatsapp-icon-container-1'>
                            <WhatsAppIcon className="whatsapp-icon-1"
                                sx={{
                                    color: "#5452ff",
                                    fontSize: {xs: '3vw', sm: '0.9vw'}
                                }} />
                        </span>
                        <a
                            href="https://wa.link/5w0okn"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="whatsapp-link-1"
                        >
                            Contáctanos
                        </a>
                        ───
                    </Typography>
                </div>
            </Container>
        </>
    );
}

export default AddressDetail;
