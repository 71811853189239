import React from 'react';
import Header from "../components/Header/Header";
import './LayoutBase.css'
const BaseLayout = ({ sidebarComponent, mainContent, additionalComponent, additionalClass }) => {
    return (
        <div className={`layout ${additionalClass}`}>
            {/*<Header />*/}
            <div className="app-container">
                <div className={`content-container ${additionalClass}`}>
                    {sidebarComponent}
                    {mainContent}
                    {additionalComponent}
                </div>
            </div>
        </div>
    );
};

export default BaseLayout;