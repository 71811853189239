import React, { useState, useEffect } from 'react';
import {Container, Grid, Button, IconButton} from "@mui/material";
import "./FinalPrice.css";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalAirportRoundedIcon from '@mui/icons-material/LocalAirportRounded';
import LockIcon from '@mui/icons-material/Lock';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CustomModal from "../components/CustomModal/CustomModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSection } from '../../context/SectionContext';
import AlertResponsive from '../../images/alerta.png'
import { Link } from 'react-router-dom';
import useRedirectIframe from "../../hooks/useRedirectIframe";

function NumberFormatter(number) {
    return number.toLocaleString()
}

function FinalPrice({ datos }) {
    const { changeSection } = useSection();

    useRedirectIframe('https://wuuju.co/pages/calculadora');

    useEffect(() => {
        console.log("Datos recibidos en FinalPrice:", datos);
    }, [datos]);

    const [recuadroVisible, setRecuadroVisible] = useState(false);
    const today = new Date();
    const dateString = today.toLocaleDateString('es-ES');
    const logistic = NumberFormatter(datos.logist);
    const dolar = NumberFormatter(datos.dolar_today);
    const product_price = NumberFormatter(datos.cop_product);

    let international_ship, insurance, final_price, tax, insurance_cal, servicio;

    if (datos.service === 'shopper') {
        servicio = "PERSONAL SHOPPER"
        international_ship = NumberFormatter(datos.cop_ship_shopper + datos.cop_tax_shopper);
        insurance = NumberFormatter(datos.cop_insurance_shopper);
        tax = datos.cop_tax_shopper;
        final_price = datos.cop_ship_shopper + datos.cop_product;
        insurance_cal = datos.cop_insurance_shopper;
    } else if (datos.service === 'locker') {
        servicio = "CASILLERO"
        international_ship = NumberFormatter(datos.cop_ship_locker + datos.cop_tax_locker);
        insurance = NumberFormatter(datos.cop_insurance_locker);
        tax = datos.cop_tax_locker;
        final_price = datos.cop_ship_locker;
        insurance_cal = datos.cop_insurance_locker;
    }

    if (datos.insurance){
        final_price = NumberFormatter(final_price + tax + datos.logist + insurance_cal)
    }
    else{
        final_price = NumberFormatter(final_price + tax + datos.logist)
    }

    const handleClick = () => {
        setRecuadroVisible(!recuadroVisible);
    };

    const handleClose = () => {
        setRecuadroVisible(false);
    };


    useEffect(() => {
        document.body.classList.toggle('recuadro-abierto', recuadroVisible);
    }, [recuadroVisible]);

    return (
        <>
            <IconButton
                aria-label="close"
                onClick={() => changeSection('calculator')}
                style={{ position: 'absolute', left: '1vw', top: '0.2vw'}}
            >
                <ArrowBackIcon sx={{
                            color: "#5452ff",
                            fontSize: { xs: '5vw', sm: '1vw', md: '1vw', lg: '1.5vw', xl: '2vw' },
                            p: "1px"
                        }}/> <span className='boton-regresar' style={{paddingLeft: '0.2vw', fontFamily:'Viga'}}>Regresar</span>
            </IconButton>
            <Container maxWidth="xl">
            <div className='modalidad-container'>
                <p className='modalidad-text'>Este es el resultado para la modalidad de:</p>
                    <div className='servicio-container'>
                        <span className='servicio-text'>{servicio}</span>
                    </div>
            </div>
                
                <div className='content-title-text'>
                    <span className='title-text'>¿Lo viste? ¡Lo hicimos de nuevo!</span>
                    <span className='title-text2'>Un SÚPER precio para un SÚPER servicio</span>
                </div>
                <div className='total'>
                    <span>${final_price}*</span>
                </div>
                        <div className='undertext-new'>
                <span className='paragraph1'>Este es el valor final que pagarías hasta tu casa, ni un centavito más*.</span>
                </div>
                <div className='under-text'>
                    ─── Válido por 24 horas* <AccessTimeIcon
                        sx={{
                            color: "#5452ff",
                            fontSize: { xs: '5vw', sm: '1vw', md: '1.2vw', lg: '1.6vw', xl: '1.6vw' },
                            p: "1px"
                        }} /> ───
                </div>
                       
                <img className="alert-responsive" src={AlertResponsive} alt=""></img>
                    <div className='text-container-p'>
                    
                    <span className='parrafo-sorpresa'>No habrán sorpresas, costos ocultos, reajustes por parte de terceros o cualquier cosa
                    que se te ocurra. Este costo lo <span className='paragraph-color'>INCLUYE TODO* </span>desde Miami a tu casa. Yeiiiii. 
                        Si lo prefieres, puedes ver el detalle de este valor{' '}
                        <span onClick={handleClick} className='textoClickeable'>
                            aquí
                        </span>
                    </span>
                </div>
                            
                <Grid container spacing={0} justifyContent="center">
                    <Grid item>
                        {/* Utiliza un div para alinear el icono con el texto en el botón */}
                        <div className='boton2-container'>
                        <Link to="/crear-casillero">
                            <Button id="boton2" variant="contained" color="primary">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Crear mi casillero ahora mismo     <LocalShippingIcon style={{ marginLeft: '8px' }} sx={{
                            fontSize: { xs: '3vw', sm: '1.5vw', md: '1.5vw', lg: '2vw', xl: '2.2vw' },
                            p: "1px"
                        }} />
                                </div>
                            </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>

                <div className='final-text'>
                    <span>¿Ya tienes una Wuuju-cuenta?.</span><a href="/inicio-sesion">Inicia sesión</a>
                </div>
                <div className='disclaimer'>
                    *Podrá verse alterado por la variación en la tasa representativa del mercado T.R.M. del dólar
                </div>
            </Container>

            {recuadroVisible && (
                <>
                    {/* Reemplaza el Backdrop y Dialog con el CustomModal */}
                    <CustomModal
                        open={recuadroVisible}
                        onClose={handleClose}
                        title={
                            <>
                                <span className="tituloRecuadro">CUENTAS CLARAS <br /> CHOCOLATE ESPESO</span>
                            </>
                        }
                        content={
                            <>
                                <p className="textoUsd">T.R.M 1 USD = ${dolar} COP {dateString}</p>
                                <div className="subtitle">
                                    <h2 className="subtitulo">
                                        <LocalAirportRoundedIcon style={{ color: "#5452ff", fontSize: "1.6vw" }} /> Precio Producto
                                    </h2>
                                    <p className="contenido">${product_price} COP </p>
                                </div>
                                <div className="subtitle">
                                    <h2 className="subtitulo">
                                        <LocalAirportRoundedIcon style={{ color: "#5452ff", fontSize: "1.6vw" }} /> Envío Internacional
                                    </h2>
                                    <p className="contenido">${international_ship} COP </p>
                                </div>
                                <div className="subtitle">
                                    <h2 className="subtitulo">
                                        <WarehouseIcon style={{ color: "#5452ff", fontSize: "1.6vw" }} /> Gastos Logísticos
                                    </h2>
                                    <p className="contenido">${logistic} COP</p>
                                </div>
                                <div className="subtitle">
                                    <h2 className="subtitulo">
                                        <LockIcon style={{ color: "#5452ff", fontSize: "1.6vw"} }/> Seguro
                                    </h2>
                                    <p className="contenido">${insurance} COP</p>
                                </div>
                                <div className="subtitle">
                                    <h2 className="subtitulo">
                                        <CardGiftcardIcon style={{ color: "#5452ff", fontSize: "1.6vw" }} /> Envío a casa
                                    </h2>
                                    <p className="contenido-tachado">$62,000 COP</p><span className="gratisText">Gratis</span>

                                </div>
                            </>
                        }
                        buttonText="Listo"
                        onButtonClick={handleClose}
                        modalClass="recuadro"  // Asegúrate de tener esta clase en tu CSS
                        titleClass="tituloRecuadro"
                        contentClass="textoUsd" // Puedes ajustar las clases según tus necesidades
                        buttonClass="botonListo"
                    />
                </>


            )}
        </>
    );
}

export default FinalPrice;