import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDatosUser } from "../context/userContext";

export const useAuth = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const { changeName, changeCompleted, changeWuujuID, changePhone } = useDatosUser();

    useEffect(() => {
        axios.get('https://back.wuuju.co/wuuju/api/authenticated-user/', { withCredentials: true })
            .then(response => {
                console.log(`Hola, ${response.data.username}!`);
                changeName(response.data.username);
                changeWuujuID(response.data.wuuju_id);
                changePhone(response.data.phone);
                setIsAuthenticated(true);
                if (response.data.birthdate){
                    changeCompleted(true);
                }
            })
            .catch(error => {
                console.error('No autenticado:', error);
                setIsAuthenticated(false);
            });
    }, [navigate, changeCompleted, changeName, changeWuujuID, changePhone]);

    return isAuthenticated;
};