import React from 'react';
import {Dialog, DialogTitle, DialogContent, Button, IconButton, Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Importa el ícono de cierre


const CustomModal = ({ open, onClose, title, content, buttonText, onButtonClick, image, sx, buttonText2, onButtonClick2, invertOrder, disableb2 }) => {

  const elements = [
  <DialogTitle key="title" sx={{ ...sx?.title, display: title ? 'block' : 'none' }}>
        {title}
      </DialogTitle>,

    <div key="image" style={{ display: image ? 'block' : 'none', position: 'relative' }}>
      <img
        alt="Modal Image"
        src={image}
        style={{
          width: '10vw',
          ...sx?.image,
        }}
      />
    </div>,

  ];

  // Si invertOrder es true, invierte el orden de los elementos
  const orderedElements = invertOrder ? [...elements].reverse() : elements;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      }}
      PaperProps={{
        sx: {
          width: '40vw',
          borderRadius: '2vw',
          overflow: 'hidden',
          alignItems: 'center',
          ...sx?.paper,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: 'absolute', right: '8px', top: '8px', ...sx?.closeButton }}
      >
        <CloseIcon />
      </IconButton>

      {orderedElements}

      <DialogContent sx={{ ...sx?.content }}>
        <p>{content}</p>
      </DialogContent>
      <Grid sx={{ display: 'flex', gap: '2vw', ...sx?.gridbutton}}>
        <Button onClick={onButtonClick} sx={{ ...sx?.button }}>
          {buttonText}
        </Button>
        {
            buttonText2 && onButtonClick2 && (
                <Button onClick={onButtonClick2} sx={{ ...sx?.button2 }} disabled={disableb2}>
                  {buttonText2}
                </Button>
            )
        }
      </Grid>
    </Dialog>
  );
};

export default CustomModal;