import * as React from 'react';
import { AppBar, Box, Toolbar, Container, Button, Grid, Typography, Menu, IconButton, MenuItem } from '@mui/material';
import Logo from '../../images/wuuju_logo.webp';
import { SvgIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useSection } from '../../context/SectionContext';
import { useLocation } from 'react-router-dom';

const pages = [
    { label: 'Tienda', action: 'redirect', url: 'https://wuuju.co/pages/search-results-page' },
    { label: 'Crear Casillero', action: 'redirectlocal', url: '/crear-casillero'},
    { label: 'Calculadora', action: 'redirectlocal', url: '/calculadora', section: 'calculator'},
];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const { activeSection } = useSection();
    const location = useLocation();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handlePageClick = (page) => {
        if (page.action === 'sectionChange') {
            window.location.href = page.url;
        } else if (page.action === 'redirectlocal') {
            window.location.href = page.url;
        } else if (page.action === 'redirect') {
            window.open(page.url, '_blank');
        }
    };

    return (
        <AppBar position="static" sx = {{bgcolor: '#121d23'}}>
            <Container maxWidth="xl">
                <Toolbar>

                    {/* Web Header*/}
                    <Grid container sx={{ display: { xs: 'none', sm: 'flex' }}} alignItems="center" >
                        <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Link to="https://wuuju.co/">
                            <SvgIcon className="Log" viewBox="0 0 48 48" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '2.8rem'}}>
                                <image href={Logo} x="0" y="0" width="48" height="48" />
                            </SvgIcon>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center'}}>
                                {pages.map((page) => (
                                    <Button
                                        key={page.label}
                                        onClick={() => handlePageClick(page)}
                                        sx={{
                                            my: 2,
                                            color: 'white',
                                            display: 'block',
                                            fontSize: activeSection === page.section || location.pathname === page.url ? '0.82rem' : '0.76rem', // Aumenta el tamaño si está activo
                                            fontWeight: activeSection === page.section || location.pathname === page.url ? 'bold' : 'normal', // Opcional: Cambiar el peso de la fuente
                                        }}
                                    >
                                        {page.label}
                                    </Button>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {/* Espacio en blanco */}
                        </Grid>
                    </Grid>

                    {/* Movil Header*/}
                    <Grid container sx={{ display: { xs: 'flex', sm: 'none' }}} alignItems="center" >
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' }}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={page.label}
                                                  onClick={() => {
                                                      handleCloseNavMenu();
                                                      handlePageClick(page);
                                                  }}>
                                            <Typography textAlign="center" >{page.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="https://wuuju.co/" >
                            <SvgIcon className="Log" viewBox="0 0 48 48" sx={{ display: { xs: 'flex', sm: 'none' }, fontSize: '2.5rem'}}>
                                <image href={Logo} x="0" y="0" width="48" height="48" />
                            </SvgIcon>
                            </Link>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
                        </Grid>

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;