import React, {useEffect, useState} from 'react';
import { Container, Grid, Typography,} from "@mui/material";
import CustomInput from '../components/CustomInput/CustomInput';
import InputSelect from "../components/CustomInputSelect/InputSelect";
import Lock from "../../images/Lock.png";
import Approved from "../../images/Check_aproveed.png";
import Message from "../Prealerts/MessageComponent/Message"
import CustomModal from "../components/CustomModal/CustomModal";
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import 'react-toastify/dist/ReactToastify.css';
import {
    ArticleOutlined as ArticleOutlinedIcon,
    ShoppingCartOutlined as ShoppingCartOutlinedIcon,
    PaidOutlined as PaidOutlinedIcon,
    Inventory2Outlined as Inventory2OutlinedIcon,
    StorefrontOutlined as StorefrontOutlinedIcon,
    AirplaneTicketOutlined as AirplaneTicketOutlinedIcon,
    PlaceOutlined as PlaceOutlinedIcon,
    LocalShippingOutlined as LocalShippingOutlinedIcon,
    SystemSecurityUpdateGoodOutlined as SystemSecurityUpdateGoodOutlinedIcon,
    SystemSecurityUpdateOutlined as SystemSecurityUpdateOutlinedIcon,
} from "@mui/icons-material";
import LoadingButton from "../components/LoadingButton/LoadingButton";
import DatePicker from "../components/DateField/DateField";
import InputFile from "../components/InputFile/InputFile";
import './Prealerts.css'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {useDatosUser} from "../../context/userContext";
import useRedirectIframe from "../../hooks/useRedirectIframe";


const Prealerts = () => {
    // Constantes para los valores del formulario
    const [nameBuy, setNameBuy] = useState('');
    const [productType, setProductType] = useState('');
    const [usdValue, setUsdValue] = useState('');
    const [invoice, setInvoice] = useState(null);
    const [boxQuantity, setBoxQuantity] = useState('');
    const [storeBuy, setStoreBuy] = useState('');
    const [miamiAddress, setMiamiAddress] = useState('');
    const [tracking, setTracking] = useState('');
    const [company, setCompany] = useState('');
    const [dateSend, setDateSend] = useState(null);
    const [dateArrival, setDateArrival] = useState(null);
    const [needSignature, setNeedSignature] = useState(false);

    // Booleanos de validación para valores del formulario
    const [isNameBuyValid, setIsNameBuyValid] = useState(null);
    const [isProductTypeValid, setIsProductTypeValid] = useState(null);
    const [isUsdValueValid, setIsUsdValueValid] = useState(null);
    const [isInvoiceValid, setIsInvoiceValid] = useState(null);
    const [isBoxQuantityValid, setIsBoxQuantityValid] = useState(null);
    const [isStoreBuyValid, setIsStoreBuyValid] = useState(null);
    const [isMiamiAddressValid, setIsMiamiAddressValid] = useState(null);
    const [isTrackingValid, setIsTrackingValid] = useState(null);
    const [isCompanyValid, setIsCompanyValid] = useState(null);
    const [isDateSendValid, setIsDateSendValid] = useState(null);
    const [isDateArrivalValid, setIsDateArrivalValid] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDeclaro, setOpenDeclaro] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const isAuthenticated = useAuth();
    const { completed } = useDatosUser();

    useRedirectIframe('https://wuuju.co/pages/casillero-virtual/');

    useEffect(() => {
        if (isAuthenticated === null) {
            // En espera
        } else if (isAuthenticated === false) {
            navigate('/inicio-sesion');
        } else if (completed) {
            navigate('/direcciones');
        }
    }, [isAuthenticated, completed, navigate]);

    if (isAuthenticated === null) {
        return <div>Cargando...</div>;
    }

    const handleReviewButtonClick = () => {
        setOpenDeclaro(false);
    };

    const handleConfirmButtonClick = async () => {
        setIsSubmitting(true);

        // Usa FormData para manejar datos y archivo
        const formData = new FormData();
        formData.append('item_name', nameBuy);
        formData.append('product_category', productType);
        formData.append('usd_price', usdValue);
        formData.append('provider', storeBuy);
        formData.append('shipping_address', miamiAddress);
        formData.append('tracking', tracking);
        formData.append('shipping_date', dateSend);
        formData.append('arrival_date', dateArrival);
        formData.append('arrival_signature', needSignature);
        formData.append('invoice', invoice); // Asegúrate de que 'invoice' es el archivo a cargar

        try {
            const response = await axios.post('https://back.wuuju.co/wuuju/api/prealert-package/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            if (response.status === 201) {
                setIsModalOpen(true);
                setOpenDeclaro(false);
            }
        } catch (error) {
            setOpenDeclaro(false);
            toast.error("Presentamos un problema al intentar generar tu paquete. Intenta nuevamente o comunicate con nosotros.", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    marginTop: '40px',
                    width: '300px',
                    left: '-5%',
                },
            });
            console.error('Error al realizar la petición:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const validateNameBuy = (name) => {
        return Promise.resolve(name.trim() !== '');
    };

    const validateProductType = (type) => {
        return Promise.resolve(type.trim() !== '');
    };

    const validateUsdValue = (value) => {
        return Promise.resolve(!isNaN(value) && Number(value) > 0);
    };

    const validateInvoice = (invoice) => {
        return Promise.resolve(invoice !== null);
    };

    const validateBoxQuantity = (quantity) => {
        return Promise.resolve(quantity && Number(quantity) > 0);
    };

    const validateStoreBuy = (store) => {
        return Promise.resolve(store.trim() !== '');
    };

    const validateMiamiAddress = (address) => {
        return Promise.resolve(address.trim() !== '');
    };

    const validateTracking = (trackingNumber) => {
        return Promise.resolve(trackingNumber.trim() !== '');
    };

    const validateCompany = (companyName) => {
        return Promise.resolve(companyName.trim() !== '');
    };

    const validateDateSend = (date) => {
        return new Promise((resolve) => {
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            const providedDate = new Date(date);

            if (providedDate < oneWeekAgo) {
                const toastMessage = (
                    <div>
                        <span>La fecha de envío es de hace más de una semana, en este caso <a href="https://wa.link/dw4lfj">Contáctanos</a></span>
                    </div>
                );
                toast.error(toastMessage, {
                    position: 'top-center',
                    autoClose: 2500,
                    style: {
                        fontFamily: 'Viga',
                        fontSize: '12px',
                        top: '10px',
                        height: '70px',
                        marginTop: '40px',
                        width: '300px',
                        left: '-5%',
                    },
                });
                resolve(false);
            } else {
                resolve(true);
            }
        });
    };

    const validateDateArrival = (date) => {
        return Promise.resolve(date !== null);
    };

    // Funcion de validación de dos semanas de ArrivalDate
    const validateDateArrivalTwoWeeks = (date) => {
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() + 14); // Obtener la fecha hace dos semanas

        const providedDate = new Date(date);
        return providedDate > twoWeeksAgo;
    }

    const handlePrealert = async () => {
        // Ejecuta todas las validaciones y guarda los resultados
        const validationResults = await Promise.all([
            validateNameBuy(nameBuy),
            validateProductType(productType),
            validateUsdValue(usdValue),
            validateInvoice(invoice),
            validateBoxQuantity(boxQuantity),
            validateStoreBuy(storeBuy),
            validateMiamiAddress(miamiAddress),
            validateTracking(tracking),
            validateCompany(company),
            validateDateSend(dateSend),
            validateDateArrival(dateArrival),
        ]);

        // Actualiza el estado basado en los resultados de las validaciones
        setIsNameBuyValid(validationResults[0]);
        setIsProductTypeValid(validationResults[1]);
        setIsUsdValueValid(validationResults[2]);
        setIsInvoiceValid(validationResults[3]);
        setIsBoxQuantityValid(validationResults[4]);
        setIsStoreBuyValid(validationResults[5]);
        setIsMiamiAddressValid(validationResults[6]);
        setIsTrackingValid(validationResults[7]);
        setIsCompanyValid(validationResults[8]);
        setIsDateSendValid(validationResults[9]);
        setIsDateArrivalValid(validationResults[10]);

        // Comprueba si todos los campos son válidos
        const allValid = validationResults.every(result => result);

        if (!allValid) {
            // Si hay algun campo sin llenar enviar alerta de llenado
            toast.error("Por favor, llena todos los campos correctamente.", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    marginTop: '40px',
                    width: '300px',
                    left: '-5%',
                },
            });
        } else if (!validationResults[3]) { // Lo mismo de !isInvoceValid
            toast.error("Recuerda adjuntar tu factura", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    marginTop: '40px',
                    width: '300px',
                    left: '-5%',
                },
            });
        } else if (validateDateArrivalTwoWeeks(dateArrival)) {
            const handleYesButtonClick = () => {
                setOpenDeclaro(true);
                toast.dismiss();
              
            };
            
            const handleNoButtonClick = () => {
                setIsDateArrivalValid(false);
                toast.dismiss();
                
            };
            const toastMessage2 = (
                <div>
                    <span>¿Estás seguro(a) de la fecha de llegada? </span>
                    <div className="div-boton-toast"><button className="boton-toast-yes" onClick= {handleYesButtonClick}>Sí</button>
                    <button className="boton-toast-no" onClick= {handleNoButtonClick}>No</button></div>
                </div>
            );

            toast.info(toastMessage2, {
                position: 'top-center',
                autoClose: 10000,
                style: {
                    textAlign:"center",
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    marginTop: '40px',
                    width: '300px',
                    left: '-5%',
                },
            });
        }
        else {
            setOpenDeclaro(true);
        }
    };

    const tipoProductoOptions = [
        { value: '1', label: 'Celular' },
        { value: '2', label: 'Laptop' },
        { value: '3', label: 'Tablet' },
        { value: '4', label: 'Torre (PC Escritorio)' },
        { value: '5', label: 'Líquidos (Perfumes, Lociones, Químicos)' },
        { value: '6', label: 'Cosméticos' },
        { value: '7', label: 'Otro' },
    ];

    const tiendaCompraOptions = [
        { value: '1', label: 'Amazon' },
        { value: '13', label: 'eBay' },
        { value: '7', label: 'NewEgg' },
        { value: '2', label: 'Apple' },
        { value: '4', label: 'BestBuy' },
        { value: '3', label: 'Walmart' },
        { value: '6', label: 'B&H Photo' },
        { value: '5', label: 'Steam' },
        { value: '21', label: 'Otro' },
    ];

    const directionMiamiOptions = [
        { value: '1', label: 'Menor a 200 USD Express' },
        { value: '2', label: 'Menor a 200 USD' },
        { value: '3', label: 'Mayor a 200 USD' },
        { value: '4', label: 'Compras Apple' },
    ];

    const empresaTransOptions = [
        { value: '1', label: 'Amazon (TBA)' },
        { value: '2', label: 'UPS' },
        { value: '3', label: 'USPS' },
        { value: '4', label: 'FedEx' },
        { value: '5', label: 'DHL' },
        { value: '6', label: 'ChinaPost' },
        { value: '7', label: 'Otro' },
    ];


    return (
        <>
            <Container maxWidth="xl" className="prealertas-content" sx={{ width: "80%" }}>
                <Typography
                    className="custom-title-new"
                    variant="h1"
                    sx={{
                        color: '#5452e1',
                        fontFamily: 'Viga, sans-serif',
                        fontSize: { xs: '8vw', sm: '2vw' },
                        textAlign: 'center',
                        lineHeight: 'normal',
                        my: 'auto',
                        marginTop: '1px',
                        marginBottom: { xs: '3.5vw', sm: '1vw' },
                    }}
                >
                    Pre-alertanos de tu compra
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            placeholder="Nombre de lo que compraste"
                            value={nameBuy}
                            setValue={setNameBuy}
                            startIcon={<ShoppingCartOutlinedIcon />}
                            isValid={isNameBuyValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputSelect
                            placeholder="Tipo de producto"
                            value={productType}
                            setValue={setProductType}
                            options={tipoProductoOptions}
                            startIcon={<ShoppingCartOutlinedIcon />}
                            isValid={isProductTypeValid}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            placeholder="Valor declarado USD"
                            type="number"
                            value={usdValue}
                            setValue={setUsdValue}
                            startIcon={<PaidOutlinedIcon />}
                            isValid={isUsdValueValid}
                            customStyles={{
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputFile
                            placeholder="Adjunta tu factura de compra"
                            file={invoice}
                            setFile={setInvoice}
                            startIcon={<ArticleOutlinedIcon />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            placeholder="Cantidad por envío - caja"
                            value={boxQuantity}
                            setValue={setBoxQuantity}
                            startIcon={<Inventory2OutlinedIcon />}
                            isValid={isBoxQuantityValid}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputSelect
                            placeholder="Tienda donde compraste"
                            value={storeBuy}
                            setValue={setStoreBuy}
                            options={tiendaCompraOptions}
                            startIcon={<StorefrontOutlinedIcon />}
                            isValid={isStoreBuyValid}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <InputSelect
                            placeholder="Dirección de entrega en Miami"
                            value={miamiAddress}
                            setValue={setMiamiAddress}
                            options={directionMiamiOptions}
                            startIcon={<PlaceOutlinedIcon />}
                            isValid={isMiamiAddressValid}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <CustomInput
                            placeholder="Número de seguimiento - tracking"
                            value={tracking}
                            setValue={setTracking}
                            startIcon={<AirplaneTicketOutlinedIcon />}
                            isValid={isTrackingValid}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <InputSelect
                            placeholder="Empresa transportadora"
                            value={company}
                            setValue={setCompany}
                            options={empresaTransOptions}
                            startIcon={<LocalShippingOutlinedIcon />}
                            isValid={isCompanyValid}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            placeholder="Fecha de envío"
                            value={dateSend}
                            setValue={setDateSend}
                            startIcon={<SystemSecurityUpdateGoodOutlinedIcon />}
                            isValid={isDateSendValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            placeholder="Fecha prevista de entrega"
                            value={dateArrival}
                            setValue={setDateArrival}
                            startIcon={<SystemSecurityUpdateOutlinedIcon />}
                            isValid={isDateArrivalValid}
                        />
                    </Grid>
                </Grid>
                <ToastContainer />
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={1}>
                        <Checkbox
                            checked={needSignature}
                            color="primary"
                            className="checkbox"
                            onClick={() => setNeedSignature(!needSignature)}
                            sx={{
                                padding: '0',
                                paddingTop: '1vw',
                                '& .MuiSvgIcon-root': {
                                    fontSize: { xs: '3vw', sm: '1.5vw' },
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography
                            sx={{
                                color: '#5452e1',
                                fontFamily: 'Viga, sans-serif',
                                fontSize: { xs: '2.3vw', sm: '0.87vw' },
                                paddingTop: { xs: '3vw', sm: '1vw' },
                                marginLeft: {sm: '-1vw', md:'-1.2vw', lg:'-1.4vw', xl:'-1.7vw'}, 
                            }}
                        >
                            <span className="custom-text">
                                Se requiere firma al momento de la entrega
                            </span>
                        </Typography>
                    </Grid>
                </Grid>

                {/* MODAL: Declaro*/}
                <CustomModal
                    open={openDeclaro}
                    onClose={() => setOpenDeclaro(false)}

                    title={
                        <>
                            Declaro
                        </>
                    }
                    image={Lock}
                    content={
                        <div>
                            <p>
                                Que la  <span className="purple-text">información </span>
                                que he suministrado es completamente <span className="purple-text">cierta </span>
                                y puede ser <span className="purple-text">verificada</span> a cabalidad
                            </p>
                        </div>
                    }
                    buttonText="Revisar"
                    onButtonClick={handleReviewButtonClick}

                    buttonText2="Confirmar"
                    onButtonClick2={handleConfirmButtonClick}
                    disableb2={isSubmitting}

                    sx={{
                        title: {
                            color: '#5452e1',
                            fontSize: '3vw',
                            fontFamily: "'Viga', sans-serif",
                            textAlign: 'center',
                            lineHeight: '1',
                            marginTop: '2vw'
                        },
                        content: {
                            color: '#666',
                            fontSize: '1.2vw',
                            textAlign: 'center',
                            marginTop: '-2vw',
                            position: 'relative',
                            overflow: 'hidden'

                        },
                        button: {
                            backgroundColor: '#ff4d4d',
                            color: 'white',
                            width: '10vw',
                            fontSize: '1.2vw',
                            borderRadius: '20px',
                            fontFamily: "'Viga', sans-serif",
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            position: 'relative',
                            top: '-0.5vw',
                            transition: 'background-color 0.3s, transform 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#ff3333',
                                filter: 'brightness(105%)',
                                transform: 'scale(1.05)',
                            },
                        },
                        button2: {
                            backgroundColor: '#b3df53',
                            color: 'white',
                            width: '10vw',
                            fontSize: '1.2vw',
                            borderRadius: '20px',
                            fontFamily: "'Viga', sans-serif",
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            position: 'relative',
                            top: '-0.5vw',
                            transition: 'background-color 0.3s, transform 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#7cba29',
                                filter: 'brightness(105%)',
                                transform: 'scale(1.05)',
                            },
                        },
                        image: {
                            position: 'relative',
                            top: '6vw',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '10vw',
                            height: 'auto',
                            borderRadius: '8px',
                        },
                    }}
                />
                <Grid item xs={12} sx={{ paddingTop: '2vw' }}>
                    <LoadingButton
                        handleSubmit={handlePrealert}
                        label="Pre-alertar"
                        className="start-button"
                        loading={undefined}
                        customStyles={{
                            fontSize: {xs: "3.7vw", sm: "1.2vw"},
                            marginTop: {xs: "4vw", sm: "1.2vw"}, }}
                    />
                </Grid>
                <Message
                />

                <CustomModal
                    invertOrder={true}
                    onButtonClick={() => setIsModalOpen(false)}
                    onButtonClick2={() => setIsModalOpen(false)}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    image={Approved}
                    title={
                        <>
                            ¡Compra prealertada!

                        </>
                    }
                    content={
                        <div>
                            <p>
                                Lo hiciste increíble. Estaremos mega pendientes a <br></br> recibir tu compra. Gracias por confiar en nosotros.
                            </p>
                        </div>
                    }
                    buttonText={
                        <>

                            Pre-alertar otra compra
                        </>
                    }
                    buttonText2={
                        <>

                            Terminar
                        </>
                    }
                    sx={{
                        title: {
                            color: '#b3df53',
                            fontSize: '2.5vw',
                            fontFamily: "'Viga', sans-serif",
                            textAlign: 'center',
                            lineHeight: '1',
                            marginTop: '0.5vw'
                        },
                        content: {
                            color: 'gray',
                            fontSize: '1vw',
                            textAlign: 'center',
                            position: 'relative',
                            overflow: 'hidden'
                        },
                        button: {
                            backgroundColor: '#5452e1',
                            color: 'white',
                            width: '20vw',
                            height:"2.5vw",
                            fontSize: '1.3vw',
                            borderRadius: '2vw',
                            fontFamily: "'Viga', sans-serif",
                            marginBottom: '1vw',
                            textTransform:"none",
                            transition: 'background-color 0.3s, transform 0.3s ease',
                            display: 'flex',
                            '&:hover': {
                                backgroundColor: '#5452e1',
                                transform: 'scale(1.05)',

                            },
                        },
                        image: {
                            marginTop:"2vw",
                            width:"17vw"
                        },
                        button2: {
                            backgroundColor: '#FB8065',
                            color: 'white',
                            width: '10vw',
                            fontSize: '1.3vw',
                            height:"2.5vw",
                            borderRadius: '2vw',
                            fontFamily: "'Viga', sans-serif",
                            marginBottom: '1vw',
                            textTransform:"none",
                            transition: 'background-color 0.3s, transform 0.3s ease',
                            display: 'flex',

                            '&:hover': {
                                backgroundColor: '#FB8065',
                                transform: 'scale(1.05)',
                            },
                        },
                        gridbutton: {
                            flexDirection: "column",
                            gap:"0vw",
                            alignItems:"center",
                        }
                    }}
                />
            </Container>
        </>
    );
}

export default Prealerts;
