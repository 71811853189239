import { useEffect } from 'react';

/**
 * Hook que redirige a una URL específica si la página actual no se está ejecutando dentro de un iframe,
 * o si el referente no coincide con el dominio esperado.
 *
 * @param {string} targetUrl - La URL a la que redirigir si no estamos en el contexto correcto.
 */

const useRedirectIfNotInIframe = (targetUrl) => {
    useEffect(() => {
        // Verifica si estamos en un iframe.
        if (window.parent === window) {
          {/**  window.location.href = targetUrl; */}
        }
    }, [targetUrl]);
};

export default useRedirectIfNotInIframe;