import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const CustomInput = ({ placeholder, type, setValue, startIcon, showPasswordIcon, hidePasswordIcon, isValid, additionalClassName, customStyles, unit, hideValidation, disabled }) => {
    const [showPassword, setShowPassword] = React.useState(false);

    /* Mostrar o ocultar contraseña*/
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /* Icono de inicio */
    const startAdornment = startIcon && (
        <InputAdornment position="start">
            {startIcon}
        </InputAdornment>
    );

    /* Icono final de mostrar contraseña */
    const endAdornment =
        type === 'password' && (
            <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? (hidePasswordIcon || <VisibilityOff />) : (showPasswordIcon || <Visibility />)}
                </IconButton>
            </InputAdornment>
        );

    /* Icono de validación de campos */
    const validationIcon = isValid !== null && (
        <InputAdornment position="end">
            {isValid ? < CheckCircleIcon style={{
                color: '#6CE9B1',
            }}
                                         fontSize="small"
            /> : <CancelIcon style={{
                color: '#FB8065',
            }}
                             fontSize="small"  />}
        </InputAdornment>
    );

    /* Logica para los diferentes tipos de decoración final */
    let endAdornmentContent, endBackground;

    if (type === 'password') {
        endAdornmentContent = [endAdornment, hideValidation ? null: validationIcon];
        endBackground = '#f4f7f6'
    } else if (unit) {
        endAdornmentContent = (
            <>
                {unit && <InputAdornment position="end">{unit}</InputAdornment>}
            </>
        );
        endBackground ='linear-gradient(to right, #f4f7f6 82%, #5452E1 20%)'
    } else {
        endAdornmentContent = hideValidation ? null: validationIcon;
        endBackground = '#f4f7f6'
    }

    /* Handle para modificar value */
    const handleInputChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <TextField
            fullWidth
            type={type === 'password' && showPassword ? 'text' : type}
            placeholder={placeholder}
            className={`custom-input ${additionalClassName} ${isValid !== null && isValid ? 'valid' : isValid === false ? 'invalid' : ''}`}
            sx={{
                '& .MuiInputBase-root': {
                    background: disabled ? '#d9d9d9' : endBackground, 
                    borderRadius: {xs: '30px', sm: '0.6vw'},
                    marginTop: {xs: '5vw', sm: '1vw'},
                    fontFamily: 'Viga',
                    height: {xs: '10vw', sm: '2.5vw'},
                    fontSize: {xs: '3.2vw', sm: '1vw'},
                    paddingLeft: {xs: '3vw', sm: '0.6vw'},
                    paddingRight: {xs: '1.5vw', sm: '0.5vw'},
                    boxShadow: '0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54)',
                    ...customStyles,
                },
                '& .MuiSvgIcon-root': {
                    fontSize: {xs: '5vw', sm: '1.5vw'},
                },
                '& .MuiTypography-root':{
                    fontFamily: 'Viga',
                    fontSize: {xs: '3.2vw', sm: '0.8vw'},
                    color: '#FFF',
                    fontWeight: 'normal',
                },
                '& .MuiInputAdornment-root':{
                    marginRight: {xs: '3vw', sm: '0.3vw'}
                }
            }}
            InputProps={{
                style: {
                    fontFamily: 'Viga',
                },
                startAdornment,
                endAdornment: endAdornmentContent,
            }}
            InputLabelProps={{
                style: {
                    color: 'red',
                },
            }}
            onChange={handleInputChange}
            disabled={disabled}
        />
    );
};

export default CustomInput;
