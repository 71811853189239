import React from 'react';
import './DefaultLayout.css';
import LayoutBase from "../LayoutBase";
import Sidebar from "./components/Sidebar/Sidebar";
import MainContent from "./components/MainContent/MainContent"
import Message from "./components/Message/Message";
import {DatosFinalPriceContext} from "../../context/DatosFinalPrice";

function DefaultLayout( {section} ) {
    return (
        <DatosFinalPriceContext>
            <LayoutBase
                sidebarComponent={<Sidebar />}
                mainContent={<MainContent section={section} />}
                additionalComponent={<Message />}
                additionalClass="default-layout"
            />
        </DatosFinalPriceContext>
    );
}

export default DefaultLayout;
