import React , { useState } from 'react';
import { Container } from "@mui/material";
import CustomInput from "../components/CustomInput/CustomInput";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import {Link, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './RestorePassword.css';
import CustomModal from '../components/CustomModal/CustomModal';
import Email from "../../images/Email_send.png";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import LoadingButtonCustom from "../components/LoadingButton/LoadingButton";


function RestorePassword() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [cargando, setCargando] = useState(false)

    const navigate = useNavigate(); // Hook para obtener la función de navegación

    const handleClick = () => {
        navigate('/inicio-sesion');
    };

    const handleSendEmail = async (event) => {
        if (!email){
            const toastMessage = "Se requiere el email";

            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    top:'10px',
                    width: '300px', left: '9vw',
                },
            });
            return;
        }

        setCargando(true);
        try {
            const response = await axios.post(`https://back.wuuju.co/email/password-reset/`, { email }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setIsModalOpen(true);
                console.log(response.data.message);
            } else if (response.status === 404) {
                const toastMessage = "No estás registrado en Wuuju. ¿Es un error? Comunicate con nosotros a WhatsApp";

                toast.error(toastMessage, {
                    position: 'top-center',
                    autoClose: 2500,
                    style: {
                        fontFamily: 'Viga',
                        fontSize:'12px',
                        top:'10px',
                        width: '300px', left: '9vw',
                    },
                });
            } else {
                const toastMessage = "No se logró enviar el correo de recuperación. Comunícate con nosotros por WhatsApp.";

                toast.error(toastMessage, {
                    position: 'top-center',
                    autoClose: 2500,
                    style: {
                        fontFamily: 'Viga',
                        fontSize:'12px',
                        top:'10px',
                        width: '300px', left: '9vw',
                    },
                });
            }
        } catch (error) {
            const toastMessage = "No se logró enviar el correo de recuperación. Comunicate con nosotros por Whatsapp.";
            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    top:'10px',
                    width: '300px', left: '9vw',
                },
            });

            if (error.response) {
                //console.error(error.response.data.error);
            } else if (error.request) {
                //console.error("No se recibió respuesta del servidor");
            } else {
                //console.error("Error:", error.message);
            }
        } finally {
            setCargando(false);
        }
    };

    return (
        <>
            <Container maxWidth="xl">
                <ToastContainer position="top-center"/>
                <h1 className="title-restore-password">¿Tienes problemas para iniciar sesión?</h1>
                <div className='text-restore-password'>
                    <span>Ingresa tu correo electrónico o teléfono y te enviaremos</span>
                    <span>un enlace para que recuperes el acceso a tu Wuuju-cuenta.</span>
                </div>
                <CustomInput
                    additionalClassName="input-restore-password"
                    placeholder="Correo electrónico o teléfono"
                    type="text"
                    startIcon={<PermIdentityOutlinedIcon />}
                    hideValidation={true}
                    setValue={setEmail}
                />

                <LoadingButtonCustom
                    loading={cargando}
                    handleSubmit={handleSendEmail}
                    label="Enviar enlace de recuperación"
                    customStyles={{
                        fontSize: {xs: "3vw", sm: "1.2vw"},
                        marginTop: {xs: "4vw", sm: "1.2vw"}, 
                        marginBottom: {xs: "4vw", sm: "1.2vw"}, }}
                ></LoadingButtonCustom>

                <div className="linea-conjunto">
                    <div className="line"></div>
                    <div className="letter">O</div>
                    <div className="line"></div>
                </div>

                <div>
                    <span className="restore-password-undertext">
                        <a className="" href="/crear-casillero">Crea una nueva Wuuju-cuenta, es gratis</a>
                    </span>
                </div>

                <div className='container-button-restore'>
                    <Link to="/inicio-sesion">
                        <button className='button-login-restore'>
                            <FontAwesomeIcon className='icon-button-restore' icon={faChevronLeft}/>
                            Volver al inicio de sesión
                        </button>
                    </Link>
                </div>

                {/**MODAL CORREO ENVIADO */}
                <CustomModal
                    invertOrder={true}
                    onButtonClick={handleClick}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    image={Email}
                    title={
                        <>
                            Correo electrónico enviado

                        </>
                    }
                    content={
                        <div>
                            <p>
                                Si la dirección de correo electrónico que proporcionaste está <br></br> asociada a una Wuuju-cuenta, recibirás un mensaje con un<br></br> <span className="purple-text">enlace para recuperar el acceso de tu cuenta</span>
                            </p>
                        </div>
                    }
                    buttonText={
                        <>
                            Aceptar
                        </>
                    }
                    sx={{
                        title: {
                            color: '#5452e1',
                            fontSize: '2vw',
                            fontFamily: "'Viga', sans-serif",
                            textAlign: 'center',
                            lineHeight: '1',
                            marginTop: '1.5vw'
                        },
                        content: {
                            color: 'gray',
                            fontSize: '1vw',
                            textAlign: 'center',
                            position: 'relative',
                            overflow: 'hidden'
                        },
                        button: {
                            backgroundColor: '#b3df53',
                            color: 'white',
                            width: '10vw',
                            boxShadow:"0 3px 6px rgba(0, 0, 0, 0.1)",
                            textTransform:"none",
                            height:"2.5vw",
                            padding: "1vw",
                            fontSize: '1.4vw',
                            borderRadius: '2vw',
                            transition: 'background-color 0.3s, transform 0.3s ease',
                            fontFamily: "'Viga', sans-serif",
                            marginBottom: '1vw',
                            display: 'flex',
                            '&:hover': {
                                backgroundColor: '#b3df53',
                                transform: 'scale(1.05)',
                            },
                        },
                        image: {
                            marginTop:"2vw",
                            width:"20vw"

                        },

                    }}
                />


            </Container>
        </>
    );
}

export default RestorePassword;