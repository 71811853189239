import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export default function BoxDetail({label, variable, icon, color}) {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const handleCopyToClipboard = () => {
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = variable;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
        setTooltipOpen(true);
        setTimeout(() => {
            setTooltipOpen(false);
        }, 2000);
      
    };

    const handleClickAway = () => {
        setTooltipOpen(false);
    };
        
    return (
        <div className='formulario-container'>
            {icon}
            <span className='variables-gray-text'>{label}</span>
            <div className='span-variable-container' style={{ backgroundColor: color }}>
                <span>{variable}</span>
            </div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <Tooltip
                        title="Copiado!"
                        open={tooltipOpen}
                        placement="right"
                        arrow
                    >
                        <IconButton onClick={handleCopyToClipboard}>
                            <ArticleOutlinedIcon sx={{ fontSize: { xs: '4vw', sm: '1.5vw' } }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </div>
    );
}
