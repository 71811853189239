import React, { createContext, useContext, useState } from 'react';

const DatosUser = createContext();

export const useDatosUser = () => useContext(DatosUser);

export const DatosUserContext = ({ children }) => {
    const [nombre, setNombre] = useState('');
    const [completed, setComplete] = useState(false);
    const [wuujuID, setWuujuID] = useState(null);
    const [phone, setPhone] = useState(null);

    const changeName = (datos) => {
        setNombre(datos);
    };
    const changeCompleted = (bool) => {
        setComplete(bool);
    };
    const changeWuujuID = (value) => {
        setWuujuID(value);
    };

    const changePhone = (value) => {
        setPhone(value);
    };

    return (
        <DatosUser.Provider value={{ nombre, changeName, completed, changeCompleted, wuujuID, changeWuujuID, phone, changePhone }}>
            {children}
        </DatosUser.Provider>
    );
};
